<script setup lang="ts">
const nonce = useNonce();

interface Props {
  src: string;
  alt: string;
  width?: string;
  height?: string;
  sizes?: string;
  provider?: string;
  format?: string;
  quality?: string;
  modifiers?: Record<string, any>;
  preset?: string;
  nonce?: string;
}

interface Emits {
  (e: "load"): void;
}

withDefaults(defineProps<Props>(), {
  format: "webp",
  quality: "70",
});

defineEmits<Emits>();
</script>

<template>
  <NuxtImg
    :src="src"
    :alt="alt"
    :width="width"
    :height="height"
    :sizes="sizes"
    :provider="provider"
    :format="format"
    :quality="quality"
    :modifiers="modifiers"
    :nonce="nonce"
    :preset="preset"
    @load="$emit('load')"
  />
</template>
